// @flow
import React, { memo } from "react"

import DefaultSection from "../DefaultSection"
import Variety from "./Variety"

type Props = {|
  data?: object,
|}

const Varieties = ({
  data = {
    items: [],
    primary: {},
  },
}: Props) => {
  const { primary = {}, items = [] } = data

  return (
    <DefaultSection
      title={primary.varieties_title && primary.varieties_title.text}
      subtitle={primary.subtitle && primary.subtitle.text}
      classNames="pt-88 md:pt-96 pl-25 pr-25 pb-53 pt-42 md:flex md:flex-col md:items-center md:pt-110 md:pb-95"
      testId="varieties-section"
    >
      <div className="text-white font-primary mt-45 flex justify-around max-w-full lg:max-w-900 w-full">
        {items.map((item, key) => (
          <Variety variety={item} key={"variety-" + key} />
        ))}
      </div>
    </DefaultSection>
  )
}

export default memo<Props>(Varieties)
