// @flow
import React, { useRef } from "react"
import LazyLoad from "react-lazyload"

import {
  Button,
  LanguageDropdown,
  scrollToRef,
  isEmpty,
} from "@lesmills/gatsby-theme-common"
import type {
  ImageType,
  LabelType,
  UserType,
} from "@lesmills/gatsby-theme-common"

import HeroInformationLanding from "../HeroInformationLanding"
import HeroInformationOffers from "../HeroInformationOffers"

type HeroProps = {|
  heroImg?: ImageType,
  heroVideo?: Object,
  heroMobileImg?: ImageType,
  information?: string,
  title?: string,
  subtitle?: string,
  btnLabel?: string,
  signInBtn?: string,
  discoverText?: string,
  noteText: Array<LabelType>,
  handleClickSignInButton: () => void,
  lang: string,
  offersPage?: boolean,
  personalizedOfferPage?: boolean,
  personalized_logo?: object,
  user?: UserType,
  affiliateLogo?: string,
  lesmillsLogo?: Object,
|}

const Hero = ({
  heroImg = {
    url: "",
    alt: "",
  },
  heroVideo = {},
  heroMobileImg = {
    url: "",
    alt: "",
  },
  information = "",
  title = "",
  subtitle = "",
  btnLabel = "",
  signInBtn,
  discoverText = [],
  noteText = "",
  handleClickSignInButton,
  lang,
  offersPage = false,
  personalizedOfferPage = false,
  personalized_logo = {
    url: "",
    alt: "",
    mobile: {
      url: "",
      alt: "",
    },
  },
  user = {},
  affiliateLogo,
  lesmillsLogo = {},
}: HeroProps) => {
  const informationSectionRef = useRef(null)
  const handleScrollDown = () => {
    scrollToRef(informationSectionRef)
  }
  const heroVideoUrl = heroVideo && heroVideo.raw && heroVideo.raw.url
  return (
    <section
      className={`h-hero relative bg-black md:h-hero-lg md:bg-left-top `}
      data-cy="hero-section"
    >
      {!offersPage ? (
        <HeroInformationLanding
          title={title}
          subtitle={subtitle}
          btnLabel={btnLabel}
          discoverText={discoverText}
          noteText={noteText}
          lang={lang}
          information={information}
          handleScrollDown={handleScrollDown}
          innerRef={informationSectionRef}
        />
      ) : (
        <HeroInformationOffers
          title={title}
          information={information}
          noteText={noteText}
          handleScrollDown={handleScrollDown}
          innerRef={informationSectionRef}
          personalizedOfferPage={personalizedOfferPage}
          personalized_logo={personalized_logo}
          affiliateLogo={affiliateLogo}
          lesmillsLogo={lesmillsLogo}
        />
      )}
      {heroVideoUrl ? (
        <LazyLoad once>
          <video
            autoPlay="autoPlay"
            loop="loop"
            muted="muted"
            playsInline="playsInline"
            className="top-0 left-0 object-cover h-full w-full"
          >
            <source src={heroVideoUrl} type="video/mp4"></source>
          </video>
        </LazyLoad>
      ) : (
        <LazyLoad once>
          <picture>
            <source srcSet={heroImg.url} media="(min-width: 768px)" />
            <source srcSet={heroMobileImg.url} media="(max-width: 768px)" />
            <img
              alt={heroImg.alt}
              src={heroImg.url}
              className="h-full w-full object-cover"
              data-cy="hero-img"
            />
          </picture>
        </LazyLoad>
      )}
      <div className="absolute md:top-34 md:right-70 right-25 top-20 flex items-center">
        <LanguageDropdown
          currentLang={lang}
          type="bold"
          isLoggedIn={!isEmpty(user)}
        />
        {!offersPage ? (
          <Button
            className="btn text-black font-primary ls-1.26 md:text-xl bg-orange-400 md:w-socials-footer-wrapper w-white-logo-small md:py-5 py-2 hover:opacity-75 md:ml-50 ml-25"
            handleOnClick={handleClickSignInButton}
            testId="sign-in-button"
          >
            {signInBtn}
          </Button>
        ) : null}
      </div>
    </section>
  )
}

export default Hero
