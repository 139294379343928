// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"

import type { LabelType } from "@lesmills/gatsby-theme-common"

import {
  images,
  htmlSerializerUpdateStyle,
  linkResolver,
  ROUTES,
  // getSessionStorage,
  // CURRENT_COUNTRY,
} from "@lesmills/gatsby-theme-common"

type HeroInformationLandingProps = {|
  information?: string,
  title?: string,
  subtitle?: string,
  btnLabel?: string,
  discoverText?: string,
  noteText: Array<LabelType>,
  lang: string,
  handleScrollDown?: () => void | Promise<void>,
  innerRef?: () => void,
|}

const HeroInformationLanding = ({
  information = "",
  title = "",
  subtitle = "",
  btnLabel = "",
  discoverText = [],
  noteText = "",
  lang,
  handleScrollDown = () => {},
  innerRef,
}: HeroInformationLandingProps) => {
  // const currentCountry = getSessionStorage(CURRENT_COUNTRY)

  return (
    <>
      <LazyLoad once>
        <img
          className={`absolute ml-x-7 pt-25 md:pt-100 max-w-176`}
          alt="Logo"
          src={images.whiteLogo}
          data-cy="logo"
        />
      </LazyLoad>
      <div
        className={`absolute text-white max-w-600 left-7/100 top-x-28 md:top-1/4 md:w-full md:tf-y-1/2`}
      >
        <h2
          className={`font-primary uppercase font-bold leading-none mb-25 textShadow-base md:textShadow-header-lg tracking-wide md:mt-40 md:mb-30 text-5xxl md:text-81xl`}
        >
          {title}
        </h2>
        <p className="font-base-black text-base leading-2tight mb-30 textShadow-primary md:text-2lg md:mb-50 md:textShadow-header-lg ls-1">
          {subtitle}
        </p>
        <div className="flex justify-center w-btn-sm bg-orange-400 md:w-300 hover:opacity-75 cursor-pointer">
          {/* https://lesmillsinternational.atlassian.net/browse/LA-982 */}
          {/* If IP is NZ navigate to member-discount else to membership */}
          <Link
            to={
              // FIXME: Comment-out code LA-982
              // currentCountry === "nz"
              //   ? ROUTES(lang).MEMBER_DISCOUNT
              //   : ROUTES(lang).MEMBERSHIP
              ROUTES(lang).MEMBERSHIP
            }
            className={
              "btn font-primary text-black ls-1.26 pt-5 pb-5 md:pt-15 md:pb-15 md:text-2lg no-underline w-full"
            }
          >
            {btnLabel}
          </Link>
        </div>
        <div className={`mt-30 md:mt-50`}>
          <RichText
            render={noteText}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "text-white text-base md:text-2lg font-base-light"
            )}
          />
        </div>
      </div>
      <span
        onClick={handleScrollDown}
        className="absolute left-70 md:left-1/2 bottom-80 block no-underline"
      >
        <div className="font-primary text-white text-base uppercase mb-40">
          <p className="hidden md:inline">{discoverText}</p>
        </div>
        <LazyLoad once>
          <img
            src={images.downArrowMedium}
            alt="scroll-down"
            className="cursor-pointer relative bottom-30 m-auto h-hero-scroll-mb w-hero-scroll-mb md:h-auto md:w-auto"
            data-cy="arrow-icon"
          />
        </LazyLoad>
      </span>
      <div
        ref={innerRef}
        className={`absolute bottom-0 w-full pt-20 pb-20 pl-32 pr-32 md:pt-23 md:pb-23 text-white uppercase text-center font-primary ls-0.64 font-bold md:text-4xl md:ls-1 bg-gray-800 text-base`}
      >
        {information}
      </div>
    </>
  )
}

export default HeroInformationLanding
