// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { RichText } from "prismic-reactjs"

import type { LabelType } from "@lesmills/gatsby-theme-common"

import {
  images,
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type HeroInformationOffersProps = {|
  information?: string,
  title?: string,
  noteText: Array<LabelType>,
  handleScrollDown?: () => void | Promise<void>,
  innerRef?: () => void,
  personalizedOfferPage?: Boolean,
  personalized_logo?: object,
  affiliateLogo?: string,
  lesmillsLogo?: Object,
|}

const HeroInformationOffers = ({
  information = "",
  title = "",
  noteText = "",
  handleScrollDown = () => {},
  personalizedOfferPage = false,
  personalized_logo = {
    url: "",
    alt: "",
    mobile: {
      url: "",
      alt: "",
    },
  },
  innerRef,
  affiliateLogo,
  lesmillsLogo = {},
}: HeroInformationOffersProps) => {
  const { url = "", alt = "", mobile = {} } = personalized_logo || {}
  return (
    <>
      <div
        className={`absolute text-white top-35/100 left-22 md:left-17/100 max-w-266 md:max-w-600`}
      >
        <div className="flex mb-25">
          {personalizedOfferPage ? (
            <LazyLoad once>
              <>
                <div className={`pr-18 md:pr-22 border-r border-white my-auto`}>
                  <picture>
                    <source srcSet={url} media="(min-width: 640px)" />
                    <source srcSet={mobile.url} media="(max-width: 375px)" />
                    <img
                      className={`max-w-124 md:max-w-136 object-cover max-h-30 md:max-h-33`}
                      alt={alt}
                      src={url}
                      data-cy="personalized-logo"
                    />
                  </picture>
                </div>
                <img
                  className={`max-w-136 md:max-w-176 pl-8 md:pl-10`}
                  alt="Logo"
                  src={lesmillsLogo.url}
                  data-cy="logo"
                />
              </>
            </LazyLoad>
          ) : (
            <LazyLoad once>
              <img
                className={`max-w-124 md:max-w-136 object-cover max-h-42`}
                alt="Logo"
                src={affiliateLogo || lesmillsLogo.url}
                data-cy="logo"
              />
            </LazyLoad>
          )}
        </div>

        <h2
          className={`font-primary uppercase font-bold leading-4none md:leading-3none mb-20 textShadow-base md:textShadow-header-lg tracking-0.01 text-9xl md:text-75xl md:mb-15 md:mt-12`}
        >
          {title}
        </h2>
        <div className={`md:mt-0 max-w-168 md:max-w-600 font-base-medium`}>
          <RichText
            render={noteText.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "text-white text-base md:text-2lg font-base-medium leading-2relaxed md:leading-4relaxed"
            )}
          />
        </div>
      </div>
      <LazyLoad once>
        <span
          onClick={handleScrollDown}
          className="absolute top-7/10 md:top-75/100 left-22 md:left-17/100"
        >
          <img
            src={images.downArrowMedium}
            alt="scroll-down"
            className="cursor-pointer relative md:bottom-30 bottom-x-20 m-auto h-hero-scroll-mb w-hero-scroll-mb md:h-auto md:w-auto"
            data-cy="arrow-icon"
          />
        </span>
      </LazyLoad>
      <div
        ref={innerRef}
        className={`absolute bottom-0 w-full pt-20 pb-20 md:pt-23 md:pb-23 text-white uppercase text-center font-primary ls-0.64 font-bold md:text-4xl md:ls-1 bg-gray-800 text-2lg`}
      >
        {information}
      </div>
    </>
  )
}

export default HeroInformationOffers
