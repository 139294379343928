// @flow
import React, { useState, useEffect, memo } from "react"
import LazyLoad from "react-lazyload"
import isEqual from "lodash/isEqual"

type HeroInformationOffersProps = {|
  affiliateLogo?: string,
  underHeader: object,
  headerLogo: object,
|}

const Header = ({
  affiliateLogo,
  underHeader,
  headerLogo = {},
}: HeroInformationOffersProps) => {
  const [isScrollOver, setIsScrollOver] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (underHeader.current) {
      setIsScrollOver(window.scrollY > underHeader.current.offsetTop)
    }
  }

  return (
    <header
      className={`z-100 flex w-full items-center md:justify-start justify-center md:h-play-icon-lg h-60 md:px-24 px-16 ${
        isScrollOver
          ? "fixed bg-gray-800 top-0 left-0 offer-header-gray"
          : "absolute bg-transparent top-80 left-0 md:top-0 md:left-0 offer-header"
      }`}
    >
      <LazyLoad once>
        <img
          className="w-120 h-46 mr-10"
          alt={headerLogo.alt}
          title="Les Mills on Demand"
          src={headerLogo.url}
          data-cy="logo"
        />
      </LazyLoad>
      {affiliateLogo && (
        <>
          <div className="w-1 h-icon-base bg-white rotate-10" />
          <div className="pl-16 w-112 h-56">
            <LazyLoad once>
              <img
                className="object-contain w-auto h-full"
                alt="Logo"
                src={affiliateLogo}
                data-cy="logo"
              />
            </LazyLoad>
          </div>
        </>
      )}
    </header>
  )
}

export default memo(Header, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.affiliateLogo, nextProps.affiliateLogo) &&
    isEqual(prevProps.affiliateLogo, nextProps.underHeader)
  )
})
