import React, { memo, useState, useEffect } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import {
  linkResolver,
  htmlSerializerUpdateStyle,
  Button,
} from "@lesmills/gatsby-theme-common"

type ToastProps = {|
  redeem_button: Object,
  note_text: Object,
  offsetTop: number,
  handleOnClick: () => void,
  underRedeem: object,
|}

const StickyToast = ({
  redeem_button,
  note_text,
  underRedeem,
  handleOnClick,
}: ToastProps) => {
  const [isShowToast, setIsShowToast] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (underRedeem.current) {
      setIsShowToast(window.scrollY >= underRedeem.current.offsetTop)
    }
  }

  return (
    <div
      className={`bg-gray-800 md:bg-transparent flex justify-center items-center md:h-play-icon-lg h-65 w-full md:z-101 z-100 shadow-sm md:shadow justify-between md:justify-end fixed bottom-0 md:top-0 md:bottom-auto md:px-24 px-16 animation-ease-opacity ${
        isShowToast ? "opacity-1" : "opacity-0"
      }`}
    >
      <RichText
        render={note_text.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "md:hidden leading-14 text-xxs text-white font-base-medium"
        )}
      />
      <Button
        handleOnClick={handleOnClick}
        className="uppercase text-black bg-orange-400 hover:text-white hover:bg-gray-970 cursor-pointer text-base md:text-3xs font-primary text-center no-underline md:w-136 md:h-logo-lmod-lg w-161 h-tick-icon-lg"
      >
        {redeem_button.text}
      </Button>
    </div>
  )
}

export default memo(StickyToast, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.redeem_button, nextProps.redeem_button) &&
    isEqual(prevProps.note_text, nextProps.note_text) &&
    isEqual(prevProps.underRedeem, nextProps.underRedeem)
  )
})
