import React from "react"
import isEqual from "lodash/isEqual"
import { withPreview } from "gatsby-source-prismic"
import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  PublicRoute,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import RefinedOffers from "../components/RefinedOffers"
import { graphql } from "gatsby"

type Props = {|
  pageContext: {
    lang: string,
    location: Object,
  },
  data: Object,
|}

const OffersPage = (props: Props) => {
  const { pageContext, location, data } = props || {}
  const scriptsOfferPage = [DATA_LAYER_SCRIPT]

  if (!data) return null

  const { prismicLayout = {}, prismicOffersPage = {} } = data

  const pageContent = prismicOffersPage.data || {}

  const layout = prismicLayout.data || {}
  const lang = pageContext.unPublishedLang || pageContext.lang

  const renderOffersPage = ({ user }: UserType) => (
    <>
      {renderSEO(pageContent, location.pathname, lang, scriptsOfferPage)}
      <RefinedOffers
        data={pageContent}
        layoutData={layout}
        user={user}
        lang={lang}
      />
    </>
  )

  return <PublicRoute component={renderOffersPage} {...props} lang={lang} />
}

export const query = graphql`
  query offerQuery($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
        lmod_uace_err {
          text
        }
        body1 {
          ...LayoutBody1Navigation
        }
        sign_in_button {
          raw
          text
        }
        best_value {
          raw
          text
        }
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
    prismicOffersPage(lang: { eq: $lang }) {
      data {
        one_off_discount_offer_interval_unit {
          text
        }
        offer_interval_unit {
          text
        }
        one_off_discount_note {
          raw
        }
        per {
          text
        }
        try_it_on_us {
          raw
        }
        redeem_description {
          raw
        }
        redeem_description_with_affiliate_name {
          raw
        }
        try_it_on_us {
          raw
        }
        embedded_video_subtitle {
          raw
          text
        }
        embedded_video_title {
          raw
          text
        }
        faqs_title {
          text
          raw
        }
        hero_highlight {
          raw
          text
        }
        hero_photo {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
            ipad {
              url
            }
            large {
              url
            }
            extra_large {
              url
            }
            double_extra_large {
              url
            }
          }
        }
        hero_title {
          raw
          text
        }
        hero_video {
          url
        }
        join_community_subtitle {
          raw
          text
        }
        join_community_title {
          raw
          text
        }
        lmod_fccoupon_err {
          text
          raw
        }
        lmod_gofferds_err {
          raw
          text
        }
        lmod_lafoffer_err {
          raw
          text
        }
        lmod_lpbc_err {
          raw
          text
        }
        lmod_ocnmatch_err {
          raw
          text
        }
        lmod_rpppoint_err {
          raw
          text
        }
        lmod_saoffer_err {
          raw
          text
        }
        normally {
          raw
          text
        }
        header_logo {
          url
          alt
        }
        note_text {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        redeem_button {
          text
        }
        redeem_description {
          text
          raw
        }
        redeem_description_with_affiliate_name {
          text
          raw
        }
        redeem_information {
          raw
        }
        redeem_note {
          text
          raw
        }
        redeem_title {
          text
          raw
        }
        stream_photo {
          url
        }
        stream_subtitle {
          text
        }
        stream_title {
          text
        }
        try_the_app_image {
          url
          alt
        }
        try_the_app_subtitle {
          text
          raw
        }
        try_the_app_title {
          raw
          text
        }
        faqs_title {
          raw
          text
        }
        body3 {
          ... on PrismicOffersPageBody3Videos {
            items {
              embedded_video {
                url
                html
              }
              embedded_video_description {
                text
                raw
              }
              embedded_video_name {
                raw
                text
              }
              thumbnail_image {
                raw
                text
              }
            }
          }
        }
        body4 {
          ... on PrismicOffersPageBody4CommunityPhotos {
            items {
              community_photo {
                alt
                url
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
              }
              community_photo_hashtag {
                raw
                text
              }
              community_photo_owner_name {
                raw
                text
              }
            }
          }
        }
        body5 {
          ... on PrismicOffersPageBody5Testimonials {
            items {
              testimonial_content {
                raw
                text
              }
              testimonial_owner_name {
                raw
                text
              }
              testimonial_quote {
                raw
                text
              }
              testimonial_rating
            }
            primary {
              testimonials_title {
                raw
                text
              }
            }
          }
        }
        body6 {
          ... on PrismicOffersPageBody6Faqs {
            id
            items {
              faq_answer {
                text
                raw
              }
              faq_question {
                text
                raw
              }
            }
          }
        }
        body7 {
          ... on PrismicOffersPageBody7StreamPlatforms {
            id
            items {
              stream_device_icon {
                alt
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
                url
              }
              stream_platform_details {
                raw
                text
              }
              stream_platform_icon {
                alt
                url
              }
              stream_platform_name {
                text
                raw
              }
            }
          }
        }
        body8 {
          ... on PrismicOffersPageBody8Challenges {
            id
            items {
              challenge_photo {
                alt
                url
              }
              challenge_description {
                raw
                text
              }
              challenge_title {
                raw
              }
            }
          }
        }
        hi_there {
          raw
        }
        hello_member {
          raw
        }
        usually {
          raw
        }
        cancel_free_trial_note {
          text
          raw
        }
        copyright {
          text
        }
        cancel_free_trial_note {
          text
          raw
        }
        body1 {
          ... on PrismicOffersPageBody1Navigation {
            items {
              navigation {
                raw
                text
              }
            }
          }
          ... on PrismicOffersPageBody1Social {
            items {
              icon {
                raw
                text
              }
              link {
                url
              }
            }
          }
        }
        copyright {
          text
        }
      }
    }
  }
`

export default withPreview(
  React.memo(OffersPage, (prevProps, nextProps) => {
    return isEqual(prevProps.data, nextProps.data)
  })
)
