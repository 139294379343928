import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import Challenge from "./Challenge"

type Props = {
  challenges: Array<Object>,
}

const Challenges = ({ challenges }: Props) => {
  return (
    <section className="bg-gray-30">
      {challenges.map((item, index) => (
        <Challenge item={item} key={"challenge-" + index} index={index} />
      ))}
    </section>
  )
}

export default memo(Challenges, (prevProps, nextProps) => {
  return isEqual(prevProps.challenges, nextProps.challenges)
})
