// @flow
import React, { memo } from "react"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  variety?: Object,
|}

const Variety = ({
  variety = {
    label: "",
    number: "",
  },
}: Props) => (
  <div className="font-secondary text-white" data-cy="variety">
    <RichText
      render={variety.number && variety.number.raw}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializerUpdateStyle(
        "",
        "text-4xxl md:text-xxxl -mb-10 md:-mb-30"
      )}
    />
    <RichText
      render={variety.label && variety.label.raw}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializerUpdateStyle(
        "",
        "font-secondary uppercase text-2sm md:text-4xl"
      )}
    />
  </div>
)

export default memo<Props>(Variety)
